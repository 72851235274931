import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function SideMenu() {
    const location = useLocation();

    return (
        <div className="lay-sidemenu">
            <div className="lay-sidemenu-wrap">

                <Link className={`lay-sidemenu__set ${location.pathname.startsWith('/Admin') || location.pathname === '/passwordChangeDisplay' ? 'menu_active' : ''}`}
                    to="/AdminMaster">管理者マスタ
                </Link>
                
                <Link
                    className={`lay-sidemenu__set ${location.pathname.startsWith('/Company') ? 'menu_active' : ''}`}
                    to="/CompanyMaster"> 組織マスタ
                </Link>


                <Link
                    className={`lay-sidemenu__set ${location.pathname.startsWith('/User') ? 'menu_active' : ''}`}
                    to="/UserMaster"> ユーザマスタ
                </Link>

                <Link className={`lay-sidemenu__set ${location.pathname.startsWith('/Facility') ? 'menu_active' : ''}`}
                    to="/FacilityMaster"> 施設マスタ
                </Link>


                <Link
                    className={`lay-sidemenu__set ${location.pathname.startsWith('/CameraGauge') ? 'menu_active' : ''}`}
                    to="/CameraGaugeMaster"> カメラー計器マスタ
                </Link>

                <Link
                    className={`lay-sidemenu__set ${location.pathname.startsWith('/Mail') ? 'menu_active' : ''}`}
                    to="/MailMaster"> メールマスタ
                </Link>

                <Link
                    className={`lay-sidemenu__set ${location.pathname.startsWith('/Maintenance') ? 'menu_active' : ''}`}
                    to="/MaintenanceMaster"> メンテナンスマスタ
                </Link>

                <Link
                    className={`lay-sidemenu__set ${location.pathname.startsWith('/DeletedLog') ? 'menu_active' : ''}`}
                    to="/DeletedLogMaster"> ログ表示
                </Link>
            </div>

        <div className="menu-footer">
            <img
                src="/img/copyright.png"
                alt="Hakaru+ Copyright(C) HAKARU PLUS Corporation. All Rights Reserved."
                className="footer-image"
            />
        </div>
    </div>
    );
}

export default SideMenu;

