import ActionTypes from "../constants/ActionTypes";

const initialState = {
  loading: false,
  deletedlogedit: [],
  error: null,
  all_data_count: 0
};
const deletedlogeditReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_DELETEDLOGEDIT_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_DELETEDLOGEDIT_SUCCESS:
      return { ...state, loading: false, deletedlogedit: action.payload.data_list, alldatanumber: action.payload.all_data_count  };
    case ActionTypes.FETCH_DELETEDLOGEDIT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default deletedlogeditReducer;
