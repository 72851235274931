import ActionTypes from "../constants/ActionTypes";
  
  const initialState = {
    isFetching: false,
    otpToken: null,
    errorMessage: null,
    accessToken: null,
    refreshToken: null,
    user: null,
    otpAttemptCount: 0,
    errorCode: null,
  };
  
  const loginReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.REQUEST_ACCESS_TOKEN:
        return {
          ...state,
          isFetching: true
        };
      case ActionTypes.RECEIVE_ACCESS_TOKEN_SUCCESS:
        return {
          ...state,
          isFetching: false,
          accessToken: action.payload.accessToken,
          refreshToken: action.payload.refreshToken,
          errorMessage: null
        };
      case ActionTypes.RECEIVE_ACCESS_TOKEN_FAILURE:
        return {
          ...state,
          isFetching: false,
          accessToken: null,
          refreshToken: null,
          errorMessage: action.payload
        };
      case ActionTypes.RECEIVE_OTP_TOKEN:
        console.log("Reducer updating state with OTP token:", action.payload);
        return { 
          ...state, 
          isFetching: false, 
          otpToken: action.payload,
          errorMessage: null
      };
      case ActionTypes.RESEND_OTP_SUCCESS:
        return {
          ...state,
          otpToken: action.payload // Cập nhật otp_token mới
      };
      case ActionTypes.VERIFY_OTP_REQUEST:
        return {
          ...state,
          isFetching: true,
      };
      case ActionTypes.VERIFY_OTP_SUCCESS:
        return {
          ...state,
          isFetching: false,
          accessToken: action.payload.accessToken, // Assuming payload includes the accessToken after OTP verification
          refreshToken: action.payload.refreshToken,
          errorMessage: null,
          otpAttemptCount: 0
        };
      case ActionTypes.VERIFY_OTP_FAILURE:
        return {
          ...state,
          isFetching: false,
          errorMessage: action.payload,
          otpAttemptCount: state.otpAttemptCount + 1,
          otpToken: (state.otpAttemptCount + 1) >= 3 ? "" : state.otpToken
        };
      case ActionTypes.RESET_OTP_ATTEMPT_COUNT:
        return {
          ...state,
          otpAttemptCount: 0
      };
      case ActionTypes.REFRESH_ACCESS_TOKEN_REQUEST:
        return {
          ...state,
          isFetching: true,
      };
      case ActionTypes.REFRESH_ACCESS_TOKEN_SUCCESS:
        return {
          ...state,
          isFetching: false,
          accessToken: action.payload.accessToken,
          refreshToken: action.payload.refreshToken || state.refreshToken,
          errorMessage: null
      };
      case ActionTypes.REFRESH_ACCESS_TOKEN_FAILURE:
        return {
          ...state,
          isFetching: false,
          errorMessage: action.payload
      };
      case ActionTypes.CLEAR_ERROR:
        return {
          ...state,
          errorMessage: null
      };
      case ActionTypes.FETCH_USER_SUCCESS:
        return {
          ...state,
          user: action.payload,
          errorMessage: null
      };
      case ActionTypes.FETCH_USER_FAILURE:
        return {
          ...state,
          errorMessage: action.payload,
      };
      case ActionTypes.LOGOUT_USER:
        return {
          isFetching: false,
          otpToken: null,
          errorMessage: null,
          accessToken: null,
          refreshToken: null,
          user: null,
          otpAttemptCount: 0,
          errorCode: null,
      };
      default:
        return state;
    }
  };
  
export default loginReducer;
  