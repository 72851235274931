import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './CompanySearch.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompany } from '../../actions/companysearch';

function CompanySearch({ onSelect, onClose }) {
    const dispatch = useDispatch();
    const { data, loading, error } = useSelector((state) => state.companySearch || { data: [], loading: false, error: null });
    const [search, setSearch] = useState({ companyId: '', company_name: '' });
    const [filteredData, setFilteredData] = useState([]);


    useEffect(() => {
        dispatch(fetchCompany());
    }, [dispatch]);

    useEffect(() => {
        setFilteredData(data);
    }, [data]);
    const handleSelect = (item, id) => {
        onSelect(item, id);
        onClose();
    };

    const handleSearchChange = (event) => {
        setSearch({ ...search, [event.target.name]: event.target.value });
    };

    const handleFilterCompany = (event) => {
        event.preventDefault();
        const filtered = data.filter(item =>
            (search.companyId === '' || item.company_code.includes(search.companyId)) &&
            (search.company_name === '' || item.company_name.includes(search.company_name))
        );
        setFilteredData(filtered); 
        console.log(filtered); 

    };

    return (
        <div className="popup-wrapper-company">
            <div className="popup">
                <div className="popup-top">
                    <span>組織選択画面</span>
                    <button className="popup-close" onClick={onClose}>&times;</button>
                </div>
                <div className="popup-content">
                    <form onSubmit={handleFilterCompany}>
                        <div className="lay-search">
                            <div className="lay-search-wrap-company">
                                <div className="lay-master-set__title">
                                    <span>組織マスタ一覧</span>
                                </div>
                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="companyId" name="companyId" value={search.companyId} onChange={handleSearchChange}/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name" value={search.company_name} onChange={handleSearchChange}/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>


                                            <li>
                                            <button type="submit" className="btn_facilitylist"> 表示 </button>
                                            </li>
                                        </ul>
                                    </div>
                                <div className="lay-master-set__frame">
                                    
                                    <div className="lay-master__table">
                                            <table style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th className="col-login-id-company">組織ID</th>
                                                        <th className="col-user-name-company">組織名</th>
                                                        <th className="col-edit-company">選択</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                
                                                {filteredData.map((val, index) => (
                                                    <tr key={index} className="lay-tr">
                                                        <td className="col-login-id-company">{val.company_code}</td>
                                                        <td className="col-user-name-company">{val.company_name}</td>
                                                        <td className="col-edit-company">
                                                            <button type="button"className="mod-btn__companylist"
                                                                onClick={() => handleSelect(val.company_name, val.company_id)}>選択
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>

                                    </div>
                                </div> 
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

CompanySearch.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired
};

export default CompanySearch;
