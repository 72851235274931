import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
// import './Facility.css';
import Modal from '../Modal/Modal';
import { useDispatch } from 'react-redux';


const MailList = ({ mail = [], loading, error, fetchMail,alldatanumber }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [mailList, seMailList] = useState(['']);
    const itemsPerPage = 20;
    const [pageindex, setPageIdx] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [search, setSearch] = useState({ company_code: '', company_name: '' });

    useEffect(() => {
        fetchMail(pageindex,itemsPerPage,search.company_code,search.company_name);
    }, [fetchMail, location]);

    useEffect(() => {
        if (mail) {
            seMailList(mail);
        }
    }, [mail]);

    const handleSearchChange = (event) => {
        setSearch({ ...search, [event.target.name]: event.target.value });
    };
    
    
    const handleSearchSubmit = (event) => {
        fetchMail(pageindex,itemsPerPage,search.company_code,search.company_name);
    };
    
    return (
        <div id='Main' >
            <div id='Contents'>
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage} />
                )}

                <div id="ajax-view-disp">
                    <div className="display-main">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>メール一覧</span>
                                </div>

                                <form onSubmit={handleSearchSubmit}style={{ marginTop: '40px' }}>
                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_code" name="company_code" value={search.company_code} onChange={handleSearchChange}/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name" value={search.company_name} onChange={handleSearchChange}/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>

                                            <li>
                                            <button type="submit" className="btn_facilitylist"> 表示 </button>

                                            </li>
                                           
                                        </ul>
                                    </div>

                                </form>

                                <div className="lay-master-set__frame"style={{ marginTop: '40px' }}>
                                    <div className="lay-master__table">
                                        <form id="MailList">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="col-login-id">組織ID</th>
                                                        <th className="col-login-id">組織名</th>
                                                        <th className="col-user-name">メール TO</th>
                                                        <th className="col-api-token">メール CC</th>
                                                        <th className="col-api-token-date">メール BCC</th>
                                                        <th className="col-companyedit">編集</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {mailList.map((val, index) => (
                                                        <tr key={index} className="lay-tr">
                                                            <td className="col-login-id">{val.company_code}</td>
                                                            <td className="col-login-id">{val.company_name}</td>
                                                            <td className="col-user-name" dangerouslySetInnerHTML={{ __html: val.mail_to_list?.join('<br/>') || '' }}></td>
                                                            <td className="col-api-token" dangerouslySetInnerHTML={{ __html: val.mail_cc_list?.join('<br/>') || '' }}></td>
                                                            <td className="col-api-token-date" dangerouslySetInnerHTML={{ __html: val.mail_bcc_list?.join('<br/>') || '' }}></td>
                                                            <td className="col-companyedit">
                    
                                                                <button
                                                                    type="button"
                                                                    id={`facility_info_edit_${index}`}
                                                                    className="mod-btn__companylist"onClick={() => navigate('/MailEdit')} 
                                                                >編集
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

MailList.propTypes = {
    mail: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    alldatanumber: PropTypes.number.isRequired,
    fetchMail: PropTypes.func.isRequired
};

export default React.memo(MailList);