import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AdminList from "../components/AdminList";
import { fetchAdmins } from "../actions/adminlist";

const mapStateToProps = (state) => {
  const { adminList } = state;
  console.log("adminList:", adminList);

  return {
    admins: adminList.admins,
    loading: adminList.loading,
    error: adminList.error,
    alldatanumber: adminList.all_data_count
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAdmins
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AdminList);