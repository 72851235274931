import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchUserEditRequest = () => ({
    type: ActionTypes.USER_EDIT_REQUEST
});

export const fetchUserEditSuccess = (user) => ({
    type: ActionTypes.USER_EDIT_SUCCESS,
    payload: user
});

export const fetchUserEditFailure = (error) => ({
    type: ActionTypes.USER_EDIT_FAILURE,
    payload: error
});


export const fetchUserEdit = (user_id) => {
    return async (dispatch) => {
        dispatch(fetchUserEditRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.get(BASE_URL + 'path=user&type=get-data&id=' + user_id, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                // Check response here
                const data = response.data.respons || {};
                dispatch(fetchUserEditSuccess(data));
                return response;
            }
            else {
                const data = {
                    "userId": 1,
                    "company_id": 1,
                    "company_name" : "デモ会社",
                    "login_id": "user",
                    "user_name": "システム管理",
                    "authority_class": 9,
                    "otp_enable": 0,
                    "mail_address": "abc@gmail.com",
                    "is_delete": 0
                }
                dispatch(fetchUserEditSuccess(data));
                return data;
            }

        } catch (error) {
            dispatch(fetchUserEditFailure(error.message));
        }
    };
};

// ====================================================
export const fetchUserDeleteRequest = () => ({
    type: ActionTypes.USER_DELETE_REQUEST
});

export const fetchUserDeleteSuccess = (user) => ({
    type: ActionTypes.USER_DELETE_SUCCESS,
    payload: user
});

export const fetchUserDeleteFailure = (error) => ({
    type: ActionTypes.USER_DELETE_FAILURE,
    payload: error
});
export const deleteUser = (userId, is_delete) => {
    return async (dispatch) => {
        dispatch(fetchUserDeleteRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.post(BASE_URL + 'path=user&type=delete-data', { 'userId': userId, "is_delete":is_delete }, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                // Check response here
                if (response.data.status === 200) {
                    const message = "完全に削除しました。";
                    dispatch(fetchUserDeleteSuccess(message));
                    return message;
                }
            }
            else {
                const msg = "完全に削除しました。";
                var response = {};
                response["data"] = { "respons": { "msg": msg } }
                dispatch(fetchUserDeleteSuccess(msg));
                return response;
            }

        } catch (error) {
            dispatch(fetchUserDeleteFailure(error.message));
        }
    };
};

// ====================================================
export const updateUserRequest = () => ({
    type: ActionTypes.USER_UPDATE_REQUEST,
});

export const updateUserSuccess = (message) => ({
    type: ActionTypes.USER_UPDATE_SUCCESS,
    payload: message,
});

export const updateUserFailure = (error) => ({
    type: ActionTypes.USER_UPDATE_FAILURE,
    payload: error,
});

export const submitUserEdit = (userdata) => {
    return async (dispatch) => {
        dispatch(updateUserRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.post(BASE_URL + 'path=user&type=update-info-data', userdata, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                if (response.data.status === 200) {
                    const message = "変更しました。";
                    dispatch(updateUserSuccess(message));
                    return message;
                }
            }
            else {
                const msg = "変更しました。";
                var response = {};
                response["data"] = { "respons": { "msg": msg } }
                dispatch(updateUserSuccess(msg));
                return response;
            }

        } catch (error) {
            dispatch(updateUserFailure(error.message));
        }
    };
};
