import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

// *************管理者新規作成******************
export const registerFacilityRequest = () => ({
    type: ActionTypes.REGISTER_FACILITY_REQUEST
});

export const registerFacilitySuccess = (message) => ({
    type: ActionTypes.REGISTER_FACILITY_SUCCESS,
    payload: message
});

export const registerFacilityFailure = (error) => ({
    type: ActionTypes.REGISTER_FACILITY_FAILURE,
    payload: error
});


export const registerFacility = (payload) => {
    return async (dispatch) => {
        dispatch(registerFacilityRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.post(BASE_URL + 'path=facility&type=insert-info-data', payload, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });
                if (response.data.status === 200) {
                    const message = "登録が成功しました。";
                    dispatch(registerFacilitySuccess(message));
                    return message;
                }
            }
            else {
                const msg = "登録しました。";
                var response = {};
                response["data"] = {"respons": {"msg": msg}}
                dispatch(registerFacilitySuccess(msg));
                return response;
            }

        } catch (error) {
            dispatch(registerFacilityFailure(error.message));
        }
    };
};

