


import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchDeletedLogRequest = () => ({
    type: ActionTypes.FETCH_DELETEDLOG_REQUEST,
});

export const fetchDeletedLogSuccess = (deletedLog) => ({
    type: ActionTypes.FETCH_DELETEDLOG_SUCCESS,
    payload: deletedLog,
});

export const fetchDeletedLogFailure = (error) => ({
    type: ActionTypes.FETCH_DELETEDLOG_FAILURE,
    payload: error,
});


export const fetchDeletedLog = (admin_id) => {
    return async (dispatch) => {
        dispatch(fetchDeletedLogRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.post(BASE_URL + '/admin-list', {
                    admin_id: parseInt(admin_id, 10),
                }, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                const data = response.data.respons || [];
                dispatch(fetchDeletedLogSuccess(data));
            }
            else {
                const data = [{ 'company_code': 'kajima', 'company_name': '鹿島建設株式会社', 'lilz_management_unit_id': 'c431946d-980b-4c90-b973-cd2137c320c1', 'lilz_management_unit_name': 'MU3_鹿島建設株式会社' , 'update_date': '2023/02/03'},
                
                { 'company_code': 'osaka_gas_facilities	', 'company_name': '大阪ガスファシリティーズ', 'lilz_management_unit_id': '0456d15e-8f58-41b6-9054-9cbead68e561', 'lilz_management_unit_name': 'MU4_大阪ガスファシリティーズ', 'update_date': '2024/04/10' },
                { 'company_code': 'ihi-g.com	', 'company_name': '(株) IHIインフラシステム', 'lilz_management_unit_id': '19d4304f-8a16-4c3e-b6bf-c58484ab0f30', 'lilz_management_unit_name': 'MU5_株式会社IHIインフラシステム' , 'update_date': '2023/08/25'}
                ];
                dispatch(fetchDeletedLogSuccess(data));
            }


        } catch (error) {
            dispatch(fetchDeletedLogFailure(error.message));
        }
    };
};
