import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Header = ({ logout }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [adminName, setAdminName] = useState('');

  useEffect(() => {
    const adminId = localStorage.getItem('admin_id');
    if (adminId) {
      setIsLoggedIn(true);
      setAdminName(localStorage.getItem('admin_name'));
    }
  }, []);

  const handleLogout = () => {
    logout();
  };

  return (
    <nav className="navbar">
      <div className="container-fluid">
        <a className="navbar-brand d-flex" href="">
          <span className="mx-0 rounded overcutoff d-flex align-items-center pl-3" style={{ cursor: 'pointer', color: 'black' }}>
            巡回点検楽スルー管理画面
          </span>

        </a>
        <a className="d-flex align-items-center" onClick={handleLogout}>
          <span id="idloginnamedisplay" className="text-dark nav-link pl-1">ログアウト</span>
        </a>
        <button className="navbar-toggler ml-auto mr-2 p-0" type="button" data-toggle="collapse" data-target="#collapsibleNavbarTop">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse mr-0 justify-content-between" id="collapsibleNavbarTop">
          <span className="navbar-text ml-3 tv-content"></span>
          <ul className="navbar-nav ml-3">
            <li className="nav-item text-nowrap px-3">
              <div className="d-flex align-items-center">
              </div>
            </li>
            {/*   ログアウト */}
            {/* <li className="nav-item text-nowrap pl-3">
              <a className="d-flex align-items-center" onClick={handleLogout}>
                <span id="idloginnamedisplay" className="text-dark nav-link pl-1">ログアウト</span>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

// Header.propTypes = {
//   logout: PropTypes.func.isRequired
// };

export default Header;
