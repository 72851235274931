import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CameraChange from '../components/CameraChange';
import { fetchCameraChange, deleteCamera, sendCameraChange} from '../actions/camerachange';

const mapStateToProps = (state) => {
    const { cameraChange } = state;
    return {
        data: cameraChange.data, 
        loading: cameraChange.loading,
        error: cameraChange.error,
        dataCount: cameraChange.dataCount
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCameraChange,
    deleteCamera,
    sendCameraChange
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CameraChange);