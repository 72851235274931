import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchMailEditRequest = () => ({
    type: ActionTypes.MAIL_EDIT_REQUEST
});

export const fetchMailEditSuccess = (data) => ({
    type: ActionTypes.MAIL_EDIT_SUCCESS,
    payload: data
});

export const fetchMailEditFailure = (error) => ({
    type: ActionTypes.MAIL_EDIT_FAILURE,
    payload: error
});


export const fetchMailEdit = (company_id) => {
    return async (dispatch) => {
        dispatch(fetchMailEditRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.get(BASE_URL + 'path=mail&type=get-data&id=' + company_id, {}, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                const data = response.data.respons || {};
                dispatch(fetchMailEditSuccess(data));
                return response;
            }
            else {
                const data = {
                    "mail_to_list": ["test@gmail.com", "test1@gmail.com"],
                    "mail_cc_list": ["test234@gmail.com"],
                    "mail_bcc_list": ["test4567@gmail.com"],
                    "abnomal_alert_flag": 1,
                    "battery_alert_flag": 1,
                    "battery_alert_amount": 10,
                    "company_id": "1",
                }
                dispatch(fetchMailEditSuccess(data));
                return data;
            }

        } catch (error) {
            dispatch(fetchMailEditFailure(error.message));
        }
    };
};

export const updateMailRequest = () => ({
    type: ActionTypes.MAIL_UPDATE_REQUEST,
});

export const updateMailSuccess = (message) => ({
    type: ActionTypes.MAIL_UPDATE_SUCCESS,
    payload: message,
});

export const updateMailFailure = (error) => ({
    type: ActionTypes.MAIL_UPDATE_FAILURE,
    payload: error,
});

export const submitMailEdit = (maildata) => {
    return async (dispatch) => {
        dispatch(updateMailRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const body = {
                    mail_to_list: maildata.mail_to_list,
                    mail_cc_list: maildata.mail_cc_list,
                    mail_bcc_list: maildata.mail_bcc_list,
                    abnomal_alert_flag: maildata.abnomal_alert_flag,
                    battery_alert_flag: maildata.battery_alert_flag,
                    battery_alert_amount: maildata.battery_alert_amount,
                    company_id: maildata.company_id
                };

                const response = await axiosInstance.post(BASE_URL + 'path=facility&type=update-info-data', body, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                dispatch(updateMailSuccess(response.data.respons.msg));
                return response;
            }
            else {
                const msg = "変更しました。";
                var response = {};
                response["data"] = { "respons": { "msg": msg } }
                dispatch(updateMailSuccess(msg));
                return response;
            }

        } catch (error) {
            dispatch(updateMailFailure(error.message));
        }
    };
};

