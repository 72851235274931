import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FacilityNew from "../components/FacilityNew";
import { registerFacility } from "../actions/facilitynew";

const mapStateToProps = (state) => {
  const { facilityNew } = state;
  console.log("facilityNew:", facilityNew);

  return {
    loading: true,
    error: facilityNew.error
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      registerFacility

    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FacilityNew);