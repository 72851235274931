import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MailList from "../components/MailList";
import { fetchMail } from "../actions/maillist";

const mapStateToProps = (state) => {
  const { mailList } = state;
  console.log("mailList:", mailList);

  return {
    mail: mailList.mail,
    loading: mailList.loading,
    alldatanumber: mailList.all_data_count,
    error: mailList.error
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMail
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MailList);