import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchCompanyRequest = () => ({
    type: ActionTypes.FETCH_COMPANIES_REQUEST,
});

export const fetchCompanySuccess = (companies) => ({
    type: ActionTypes.FETCH_COMPANIES_SUCCESS,
    payload: companies
});

export const fetchCompanyFailure = (error) => ({
    type: ActionTypes.FETCH_COMPANIES_FAILURE,
    payload: error
});


export const fetchCompany = (company_code, company_name) => {
    return async (dispatch) => {
        dispatch(fetchCompanyRequest());
        try {
            if (!window.debug) {
                let strFilter = "";
                if (company_code) {
                    strFilter += `&company_code=${company_code}`;
                }
                if (company_name) {
                    strFilter += `&company_name=${company_name}`;
                }
                const accessToken = localStorage.getItem('accessTokenCompany');
                const response = await axiosInstance.get(BASE_URL + 'path=company&type=get-list-data' + strFilter,
                    {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                        }
                    });

                const data = response.data.respons || {"data_list": [], "all_data_count":0};
                dispatch(fetchCompanySuccess(data));
            }
            else {
                const data = {"data_list": [{
                    'company_id': 1, 'company_code': 'konoike', 'company_name': '鴻池運輸株式会社', "manage_unit_id": "d596b52c-aaa0-4681-8395-20ab0a7f6640	",
                    "manage_unit_name": "MU999_レンタル品管理用	", "create_date": "2020/07/07", "is_hidden": 0
                },
                { 'company_id': 2, 'company_code': 'konoike', 'company_name': 'デモ会社', "manage_unit_name": "MU999_レンタル品管理用	", "create_date": "2020/07/07", "is_hidden": 0 },
                { 'company_id': 3, 'company_code': 'snb', 'company_name': '新日本ビルサービス株式会社', "manage_unit_name": "MU999_レンタル品管理用	", "create_date": "2020/07/07", "is_hidden": 0 },
                { 'company_id': 4, 'company_code': 'co-jsp', 'company_name': '株式会社JSP', "manage_unit_name": "MU999_レンタル品管理用	", "create_date": "2020/07/07", "is_hidden": 1 },
                { 'company_id': 5, 'company_code': 'okada-syouten', 'company_name': '岡田商店', "manage_unit_name": "MU999_レンタル品管理用	", "create_date": "2020/07/07", "is_hidden": 0 }
                ], "all_data_count": 5};
                dispatch(fetchCompanySuccess(data));
            }


        } catch (error) {
            dispatch(fetchCompanyFailure(error.message));
        }
    };
};
