import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import './CameraList.css';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';


const CameraList = ({ fetchCameras, cameras = [], loading, error }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [selectedCompanyIndex, setSelectedCompanyIndex] = useState(null); 
    const [adminId, setAdminId] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const [companyCode, setCompanyCode] = useState('');
    const [companyNameView, setCompanyNameView] = useState('');
    
    useEffect(() => {
        fetchCameras();
    }, [fetchCameras, location]);

    useEffect(() => {
        if (cameras.length > 0) {
            // Assuming you want to use the `admin_id` of the first company in the list
            setAdminId(cameras[0].admin_id || '');
        }
    }, [cameras]);

  

    return (
        <div id='Main' >
            <div id='Contents'>
                <div id="ajax-view-disp">
                    <div className="display-main">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>カメラ一覧</span>
                                </div>

                                <form  style={{ marginTop: '40px' }}>
                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="companyname" name="companyname" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="facilityname" name="facilityname" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                           
                                        </ul>
                                    </div>

                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>施設ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="companyname" name="companyname" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>施設名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="facilityname" name="facilityname" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                           
                                        </ul>
                                    </div>

                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>デバイスID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="facilityId" name="facilityId" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>カメラ名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="createDate" name="createDate" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                            <button type="submit" className="btn_facilitylist"> 表示 </button>
                                            </li>
                                        </ul>
                                    </div>

                                </form>

                                <div className="lay-master-set__frame"style={{ marginTop: '50px' }}>
                                    <div className="lay-master__table">
                                        <form id="AdminList"style={{ marginTop: '40px' }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="col-login-id">組織名</th>
                                                        <th className="col-login-id">施設名</th>
                                                        <th className="col-user-name">デバイスID</th>
                                                        <th className="col-api-token">カメラ名</th>
                                                        <th className="col-api-token-date">計器数</th>
                                                        <th className="col-edit">編集</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {cameras.map((val, index) => (
                                                        <tr key={index} className="lay-tr">
                                                            <td className="col-login-id">{val.company_name}</td>
                                                            <td className="col-user-name">{val.facility_name}</td>
                                                            <td className="col-user-name">{val.device_id}</td>
                                                            <td className="col-api-token">{val.camera_name}</td>
                                                            <td className="col-api-token-date">{val.gauge_count}</td>
                                                            <td className="col-companyedit">
                    
                                                                <button
                                                                    type="button"
                                                                    id={`facility_info_edit_${index}`}
                                                                    className="mod-btn__companylist" 
                                                                    // onClick={() => handleEditFacility(val.facility_id)}
                                                                >  編集
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CameraList.propTypes = {
    cameras: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchCameras: PropTypes.func.isRequired
};

export default React.memo(CameraList);