import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchFacilityRequest = () => ({
    type: ActionTypes.FETCH_FACILITY_REQUEST,
});

export const fetchFacilitySuccess = (facilities) => ({
    type: ActionTypes.FETCH_FACILITY_SUCCESS,
    payload: facilities,
});

export const fetchFacilityFailure = (error) => ({
    type: ActionTypes.FETCH_FACILITY_FAILURE,
    payload: error,
});


export const fetchFacility = (pageindex,itemsPerPage,company_code,company_name,facility_id,facility_name)=> {
    return async (dispatch) => {
        dispatch(fetchFacilityRequest());
        try {
            if (!window.debug) {
                let strFilter = "";
                if (company_code) {
                    strFilter += `&company_code=${company_code}`;
                }
                if (company_name) {
                    strFilter += `&company_name=${company_name}`;
                }
                if (facility_id) {
                    strFilter += `&facility_id=${facility_id}`;
                }
                if (facility_name) {
                    strFilter += `&facility_name=${facility_name}`;
                }
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.get(BASE_URL + `path=facility&type=get-list-data&page=${pageindex}&number_per_page=${itemsPerPage}` + strFilter,                     {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                const data = response.data.respons || {"data_list": [], "all_data_count":0};
                dispatch(fetchFacilitySuccess(data));
            }
            else {
                const data = {"data_list": [{ 'company_id': '1','company_name': 'ハカルプラス', 'facility_id': '59', 'facility_name': '施設１', 'create_date': '2024/11/08','is_delete':1},
                
                {  'company_id': '1','company_name': 'ハカルプラス', 'facility_id': '58', 'facility_name': 'キュービクル', 'create_date': '2024/09/23','is_delete' : 1},
                {  'company_id': '1','company_name': 'ハカルプラス', 'facility_id': '57', 'facility_name': '施設１', 'create_date': '2024/08/26', 'is_delete':0 }
                ], "all_data_count": 5};
                dispatch(fetchFacilitySuccess(data));
            }


        } catch (error) {
            dispatch(fetchFacilityFailure(error.message));
        }
    };
};
