import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import './CameraChange.css';

const CameraChange = ({ fetchCameraChange, fetchGetGaugeData, sendCameraChange, data = {}, dataCount, loading, error }) => {
    

    return (
        <div id='Main'>
            
        </div>
    );
};

CameraChange.propTypes = {
    data: PropTypes.object.isRequired,
    dataCount: PropTypes.number,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchCameraChange: PropTypes.func.isRequired,
    fetchGetGaugeData: PropTypes.func.isRequired,
    sendCameraChange: PropTypes.func.isRequired,
};

export default React.memo(CameraChange);
