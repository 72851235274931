import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchAdminEditRequest = () => ({
    type: ActionTypes.ADMIN_EDIT_REQUEST
});

export const fetchAdminEditSuccess = (admin) => ({
    type: ActionTypes.ADMIN_EDIT_SUCCESS,
    payload: admin
});

export const fetchAdminEditFailure = (error) => ({
    type: ActionTypes.ADMIN_EDIT_FAILURE,
    payload: error
});


export const fetchAdminEdit = (admin_id) => {
    return async (dispatch) => {
        dispatch(fetchAdminEditRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.get(BASE_URL + 'path=admin&type=get-data&id=' + admin_id, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                // Check response here
                const data = response.data.respons || {};
                dispatch(fetchAdminEditSuccess(data));
                return response;
            }
            else {
                const data = {
                    "admin_id": 1,
                    "login_id": "admin",
                    "admin_name": "システム管理",
                    "lilz_gauge_token": ".eJwNzN0KgjAYANB3kV0228-3zV10oUKhUoRECNHF_DYJxMRFgT19nQc4t0Q7E5yzA2VgPAWukVo1WIoYQGjkmnNINglRJM7vp3_tlFSMMZJ-muLBxAXkoLt1sqSJ-y42MrNrW4_XpWhH_lnzSebb-XyKUJVL3R7So6i-5f_rnQhBGuMM9yAD67PeKK8lokFAniX3Hxy0K8A.XmctJg.ezfxQJwCJM6iaOma1lPvhLc2lho",
                    "lilz_gauge_token_register_date": "2020/04/13",
                    "access_token": "958e1fc920152935dc32ff885b94c450cb08cb105c2f1a7dcc90904aefa110db",
                    "company_id": 1,
                    "company_name": "長谷工コーポレーション",
                    "otp_enable": 1,
                    "mail_address": "abc@gmail.com"
                }
                dispatch(fetchAdminEditSuccess(data));
                return data;
            }

        } catch (error) {
            dispatch(fetchAdminEditFailure(error.message));
        }
    };
};


export const fetchAdminDeleteRequest = () => ({
    type: ActionTypes.ADMIN_DELETE_REQUEST
});

export const fetchAdminDeleteSuccess = (admin) => ({
    type: ActionTypes.ADMIN_DELETE_SUCCESS,
    payload: admin
});

export const fetchAdminDeleteFailure = (error) => ({
    type: ActionTypes.ADMIN_DELETE_FAILURE,
    payload: error
});
export const deleteAdmin = (admin_id) => {
    return async (dispatch) => {
        dispatch(fetchAdminDeleteRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.post(BASE_URL + 'path=admin&type=delete-data', {'admin_id': admin_id}, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                // Check response here
                if (response.data.status === 200) {
                    const message = "完全に削除しました。";
                    dispatch(fetchAdminDeleteSuccess(message));
                    return message;
                }
                return response;
            }
            else {
                const msg = "完全に削除しました。";
                var response = {};
                response["data"] = { "respons": { "msg": msg } }
                dispatch(fetchAdminDeleteSuccess(msg));
                return response;
            }

        } catch (error) {
            dispatch(fetchAdminDeleteFailure(error.message));
        }
    };
};

export const updateAdminRequest = () => ({
    type: ActionTypes.FACILITY_UPDATE_REQUEST,
});

export const updateAdminSuccess = (message) => ({
    type: ActionTypes.FACILITY_UPDATE_SUCCESS,
    payload: message,
});

export const updateAdminFailure = (error) => ({
    type: ActionTypes.FACILITY_UPDATE_FAILURE,
    payload: error,
});

export const submitAdminEdit = (admindata) => {
    return async (dispatch) => {
        dispatch(updateAdminRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.post(BASE_URL + 'path=admin&type=update-info-data', admindata, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                if (response.data.status === 200) {
                    const message = "登録が成功しました。";
                    dispatch(updateAdminSuccess(message));
                    return message;
                }
            }
            else {
                const msg = "変更しました。";
                var response = {};
                response["data"] = { "respons": { "msg": msg } }
                dispatch(updateAdminSuccess(msg));
                return response;
            }

        } catch (error) {
            dispatch(updateAdminFailure(error.message));
        }
    };
};

