import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../Modal/Modal';
import './ChangePassword.css';
import message from "../../constants/message";


const ChangePassword = ({ loading, error, updatePassword, loginId, userType}) => {
    const navigate = useNavigate();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [errorMessages, setErrorMessages] = useState({});
    const location = useLocation();
    const { state } = location || {}; // Get the state object
    loginId = state.loginId
    userType  = state.userType

    const validateInput = () => {

        const errors = {};
        const minLength = 8;
        const maxLength = 32;
        if (!currentPassword.trim()) {
            errors.currentPassword = message.REQUIRED_MSG_ONLY;
        } else if (!/^[\x21-\x7E]+$/.test(currentPassword)) {
            errors.currentPassword = message.ALPHA_NUMBERIC_SYMBOL_MSG_ONLY;
        }

        if (!newPassword.trim()) {
            errors.newPassword = message.REQUIRED_MSG_ONLY;
        } else if (!/^[\x21-\x7E]+$/.test(newPassword)) {
            errors.newPassword = message.ALPHA_NUMBERIC_SYMBOL_MSG_ONLY;
        } else if (newPassword.length < minLength || newPassword.length > maxLength) {
            errors.newPassword = "8桁～32桁で入力してください。";
        } else if (currentPassword === newPassword) {
            errors.newPassword = message.DIFFERS_MSG;
        }

        if (!confirmPassword.trim()) {
            errors.confirmPassword = message.REQUIRED_MSG_ONLY;
        } else if (!/^[\x21-\x7E]+$/.test(confirmPassword)) {
            errors.confirmPassword = message.ALPHA_NUMBERIC_SYMBOL_MSG_ONLY;
        } else if (confirmPassword.length < minLength || confirmPassword.length > maxLength) {
            errors.confirmPassword = "8桁～32桁で入力してください。";
        } else if (newPassword !== confirmPassword) {
            errors.confirmPassword = message.MATCHING_MSG;
        }

        setErrorMessages(errors);
        return Object.keys(errors).length === 0;
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleUpdatePassword = async () => {
        if (!validateInput()) {
            return;
        }

        try {
            const response = await updatePassword(currentPassword, newPassword, loginId, userType);
            const message = response || "変更しました。";
            setModalMessage(message);
            setIsModalOpen(true);

            // Clear input fields after a successful update
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch (error) {
            setModalMessage(error.message || "Failed to update ID/PW.");
            setIsModalOpen(true);
        }
    };

    return (
        <div id='Main'>
            <div id='Contents'>
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage}
                    />
                )}
                <div id="ajax-view-disp">
                    <div className="display-main">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title" style={{ marginRight: '80px' }}>
                                    <span>ID/PWの変更</span>
                                </div>
                                {/* ボタン */}
                                <div className="lay-master-set__form">
                                    <form id="userData">
                                        <div className="wrapper" style={{ textAlign: 'left' }}>
                                            <div className="mail-send__set">
                                                <div className="mail-input">
                                                    {/* ボタン */}
                                                    <div style={{ width: '130%', marginTop: '10px' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            
                                                            <div className='add-btn'>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__roundborder add"
                                                                    onClick={handleUpdatePassword}
                                                                >
                                                                    変更
                                                                </button>
                                                            </div>
                                                        
                                                            <div className='back-btn'>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__adminback back"
                                                                    onClick={handleBackClick}
                                                                >
                                                                    戻る
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 設定項目 */}
                                                    <div className="mod-form__set">
                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>元のパスワード</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        type="text"
                                                                        id="current_password"
                                                                        name="current_password"
                                                                        value={currentPassword}
                                                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                                                        maxLength="32"
                                                                    />
                                                                    <div className="error_msg">
                                                                        {errorMessages.currentPassword && <span style={{ color: 'red' }}>{errorMessages.currentPassword}</span>}
                                                                    </div>
                                                                </div>
                                                            </dd>
                                                        </dl>

                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>新しいパスワード</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        type="text"
                                                                        id="new_password"
                                                                        name="new_password"
                                                                        value={newPassword}
                                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                                        maxLength="32"
                                                                    />
                                                                    <div className="error_msg">
                                                                        {errorMessages.newPassword && <span style={{ color: 'red' }}>{errorMessages.newPassword}</span>}
                                                                    </div>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>新しいパスワード(確認用)</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        type="text"
                                                                        id="confirm_password"
                                                                        name="confirm_password"
                                                                        value={confirmPassword}
                                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                                        maxLength="32"
                                                                    />
                                                                </div>
                                                                <div className="error_msg">
                                                                    {errorMessages.confirmPassword && <span style={{ color: 'red' }}>{errorMessages.confirmPassword}</span>}
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`overlay ${loading ? 'active' : ''}`}>
                <div className={`spinner ${loading ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

ChangePassword.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    updatePassword: PropTypes.func.isRequired,
    loginId: PropTypes.string,
    userType: PropTypes.string
};

export default React.memo(ChangePassword);
