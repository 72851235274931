import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MaintenanceList from "../components/MaintenanceList";
import { fetchMaintenance } from "../actions/maintenancelist";

const mapStateToProps = (state) => {
  const { maintenanceList } = state;
  console.log("maintenanceList:", maintenanceList);

  return {
    maintenance: maintenanceList.maintenance,
    loading: maintenanceList.loading,
    error: maintenanceList.error,
    alldatanumber: maintenanceList.all_data_count
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMaintenance
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceList);