import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import purify from "dompurify";
import './login.css';

const Login = React.memo(
  ({
    isFetching,
    otpToken,
    accessToken,
    refreshToken,
    errorCode,
    errorMessage,
    fetchAccessToken,
    fetchUserData,
    verifyOtp,
    resendOtp
  }) => {
    // Form state
    // const [companyId, setCompanyId] = useState('');
    const [loginId, setLoginId] = useState('');
    const [password, setPassword] = useState('');
    // const [rememberMe, setRememberMe] = useState(false);
    const [otpCode, setOtpCode] = useState('');
    const [resendNotification, setResendNotification] = useState(false);
    const [countdown, setCountdown] = useState(30);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const navigate = useNavigate();

    // Handler for form submission
    const handleLogin = (event) => {
      event.preventDefault();
      fetchAccessToken({
        // company_code: companyId,
        login_id: loginId,
        password: password
      });
      // if (rememberMe) {
      //   // localStorage.setItem('companyId', companyId);
      //   localStorage.setItem('loginId', loginId);
      //   localStorage.setItem('rememberMe', rememberMe);
      // }
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      verifyOtp(otpCode, otpToken);
    };

    const handleOtpChange = (e) => {
      setOtpCode(e.target.value);

      if (e.target.value === '') {
        setResendNotification(false);
        setCountdown(30);
      } else {
        setResendNotification(false);
      }
    };

    const handleResendClick = () => {
      resendOtp(otpToken);
      setCountdown(30);
      setResendNotification(false);
      setModalMessage('認証コードを再送信しました。');
      setModalOpen(true);
    };

    const closeModal = () => {
      setModalOpen(false);
    };

    useEffect(() => {
      // const storedCompanyId = localStorage.getItem('companyId');
      // const storedLoginId = locaslStorage.getItem('loginId');
      // const storedRememberMe = localStorage.getItem('rememberMe') === 'true';
      // if (storedRememberMe) {
      //   // setCompanyId(storedCompanyId);
      //   setLoginId(storedLoginId);
      //   setRememberMe(storedRememberMe);
      // }
      if (accessToken) {
        // fetchUserData(accessToken);
        navigate('/AdminMaster');
      }
    }, [otpToken, accessToken, navigate, fetchUserData]);

    useEffect(() => {
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');

      if (accessToken && refreshToken) {
        fetchUserData(accessToken);
        navigate('/');
      }
    }, [fetchUserData, navigate]);

    useEffect(() => {
      const interval = setInterval(() => {
        if (otpToken) {
          if (countdown > 0) {
            setCountdown(countdown - 1);
          } else {
            clearInterval(interval);
            if (!otpCode) {
              setResendNotification(true);
            }
          }
        }
      }, 1000);

      return () => clearInterval(interval);
    }, [countdown, otpCode, otpToken]);

    // Reset OTP input on error
    useEffect(() => {
      if (errorMessage) {
        setOtpCode('');
      }
    }, [errorMessage]);

    const Modal = ({ isOpen, onClose, message }) => {
      if (!isOpen) return null;

      return (
        <div className="modal-overlay">
          <div className="modal-content">
            <span className="modal-close" onClick={onClose}>✖</span>
            <p className='modal-text'>{message}</p>
            <hr className="modal-divider" />
            <button className="btn-modal-close" onClick={onClose}>OK</button>
          </div>
        </div>
      );
    };

    return otpToken ? (
      <div className="lay-login">
        <Modal isOpen={modalOpen} onClose={closeModal} message={modalMessage} />
        <div className="lay-login__sub">
          <img src="img/logo.png" alt="Logo" className="logo" />
          <div className="login-form">
            {errorMessage && <p className="error" dangerouslySetInnerHTML={{ __html:purify.sanitize(errorMessage) }} />}
            <form id="otp-form" method="post" onSubmit={handleSubmit}>
              <div className="otp-check-container">
                <div className="otp-check-div">
                  <span id="otp-code-label">認証コード</span>
                </div>
                <div className="otp-check-div">
                  <input
                    type="text"
                    id="otp_code"
                    name="otp_code"
                    maxLength="6"
                    autoComplete="off"
                    value={otpCode}
                    onChange={handleOtpChange}
                  />
                </div>
                <div className="otp-check-div">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isFetching}
                  >
                    認証
                    {isFetching && <div className="login-spinner"></div>}
                  </button>
                </div>
              </div>
            </form>
            {resendNotification && (
              <div id="resend-otp">
                <span>
                  認証コードが受信できない場合、
                  <em
                    onClick={handleResendClick}
                    style={{ cursor: 'pointer' }}
                  >
                    こちら
                  </em>
                  で再試行してください。
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    ) : (
      <div className="lay-login">
        <Modal isOpen={modalOpen} onClose={closeModal} message={modalMessage} />
        <div className="lay-login__sub">
          <img src="img/logo.png" alt="Logo" className="logo" />
          <div className="login-form">
            {errorMessage && <p className="error" dangerouslySetInnerHTML={{ __html:purify.sanitize(errorMessage) }} />}
            <form onSubmit={handleLogin}>
              {/* <label className="label-control">組織ID</label>
              <input
                type="text"
                className="form-control"
                value={companyId}
                onChange={(e) => setCompanyId(e.target.value)}
                required
              /> */}
              <label className="label-control">ログインID</label>
              <input
                type="text"
                className="form-control"
                value={loginId}
                onChange={(e) => setLoginId(e.target.value)}
                required
              />
              <label className="label-control">パスワード</label>
              <input
                type="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {/* <label className="checkbox-frame">
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                組織IDとログインIDを記憶する
              </label> */}
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isFetching}
              >
                ログイン
                {isFetching && <div className="login-spinner"></div>}
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
);

Login.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  otpToken: PropTypes.string,
  accessToken: PropTypes.string,
  refreshToken: PropTypes.string,
  errorCode: PropTypes.string,
  errorMessage: PropTypes.string,
  fetchAccessToken: PropTypes.func.isRequired,
  fetchUserData: PropTypes.func.isRequired,
  verifyOtp: PropTypes.func.isRequired,
  resendOtp: PropTypes.func.isRequired
};

// Login.defaultProps = {
//   errorMessage: ''
// };

export default Login;
