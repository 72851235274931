import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchFacilityEditRequest = () => ({
    type: ActionTypes.FACILITY_EDIT_REQUEST
});

export const fetchFacilityEditSuccess = (admin) => ({
    type: ActionTypes.FACILITY_EDIT_SUCCESS,
    payload: admin
});

export const fetchFacilityEditFailure = (error) => ({
    type: ActionTypes.FACILITY_EDIT_FAILURE,
    payload: error
});


export const fetchFacilityEdit = (facility_id) => {
    return async (dispatch) => {
        dispatch(fetchFacilityEditRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.get(BASE_URL + 'path=facility&type=get-data&id=' + facility_id, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                const data = response.data.respons || {};
                dispatch(fetchFacilityEditSuccess(data));
                return response;
            }
            else {
                const data = {
                    "facility_name": "test",
                    "facility_id": "3",
                    "company_id": 1,
                    "company_name": "長谷工コーポレーション",
                    "is_delete" :0,
                }
                dispatch(fetchFacilityEditSuccess(data));
                return data;
            }

        } catch (error) {
            dispatch(fetchFacilityEditFailure(error.message));
        }
    };
};


export const fetchFacilityDeleteRequest = () => ({
    type: ActionTypes.FACILITY_DELETE_REQUEST
});

export const fetchFacilityDeleteSuccess = (admin) => ({
    type: ActionTypes.FACILITY_DELETE_SUCCESS,
    payload: admin
});

export const fetchFacilityDeleteFailure = (error) => ({
    type: ActionTypes.FACILITY_DELETE_FAILURE,
    payload: error
});

export const deleteFacility = (facility_id, facility_name, company_id, is_delete) => {
    return async (dispatch) => {
        dispatch(fetchFacilityDeleteRequest());
        const payload = {
            facility_id: facility_id,
            facility_name: facility_name,
            company_id: company_id,
            is_delete: is_delete,
        };

        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.post(BASE_URL + 'path=facility&type=update-info-data', payload, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
            console.log('API Response:', response);

            if (response.data.status === 200) {
                const message = "完全に削除しました。";
                dispatch(fetchFacilityDeleteSuccess(message));
                return message;
            }
        } catch (error) {
            console.error('API Call Error:', error);
            dispatch(fetchFacilityDeleteFailure(error.message));
        }
    };
};

export const updateFacilityRequest = () => ({
    type: ActionTypes.FACILITY_UPDATE_REQUEST,
});

export const updateFacilitySuccess = (message) => ({
    type: ActionTypes.FACILITY_UPDATE_SUCCESS,
    payload: message,
});

export const updateFacilityFailure = (error) => ({
    type: ActionTypes.FACILITY_UPDATE_FAILURE,
    payload: error,
});

export const submitFacilityEdit = (facilitydata) => {
    return async (dispatch) => {
        dispatch(updateFacilityRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.post(BASE_URL + 'path=facility&type=update-info-data', facilitydata, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                if (response.data.status === 200) {
                    const message = "変更しました。";
                    dispatch(updateFacilitySuccess(message));
                    return message;
                }
            }
            else {
                const msg = "変更しました。";
                var response = {};
                response["data"] = { "respons": { "msg": msg } }
                dispatch(updateFacilitySuccess(msg));
                return response;
            }

        } catch (error) {
            dispatch(updateFacilityFailure(error.message));
        }
    };
};

