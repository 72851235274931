import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import UserNew from "../components/UserNew";
import { registerUser } from "../actions/usernew";

const mapStateToProps = (state) => {
  const { userNew } = state;
  console.log("userNew:", userNew);

  return {
    loading: true,
    error: userNew.error
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      registerUser
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserNew);