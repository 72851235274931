import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchMaintenanceRequest = () => ({
    type: ActionTypes.FETCH_MAINTENANCE_REQUEST,
});

export const fetchMaintenanceSuccess = (facilities) => ({
    type: ActionTypes.FETCH_MAINTENANCE_SUCCESS,
    payload: facilities,
});

export const fetchMaintenanceFailure = (error) => ({
    type: ActionTypes.FETCH_MAINTENANCE_FAILURE,
    payload: error,
});


export const fetchMaintenance = (admin_id) => {
    return async (dispatch) => {
        dispatch(fetchMaintenanceRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessTokenAdmin');
                const response = await axiosInstance.post(BASE_URL + '/admin-list', {
                    admin_id: parseInt(admin_id, 10),
                }, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                const data = response.data.respons || [];
                dispatch(fetchMaintenanceSuccess(data));
            }
            else {
                const data = [{ 'company_name': 'カミマル株式会社','screen_message': 'テスト'},
                
                { 'company_name': '東京都水道局	','screen_message': 'テスト' }
                ];
                dispatch(fetchMaintenanceSuccess(data));
            }


        } catch (error) {
            dispatch(fetchMaintenanceFailure(error.message));
        }
    };
};
export const fetchCompaniesRequest = () => ({
    type: ActionTypes.FETCH_COMPANIES_REQUEST
});

export const fetchCompaniesSuccess = (companies) => ({
    type: ActionTypes.FETCH_COMPANIES_SUCCESS,
    payload: companies
});

export const fetchCompaniesFailure = (error) => ({
    type: ActionTypes.FETCH_COMPANIES_FAILURE,
    payload: error
});

export const fetchCompanyList = (admin_id) => {
    return async (dispatch) => {
        dispatch(fetchCompaniesRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessTokenAdmin');
                const response = await axiosInstance.post(BASE_URL, {
                    admin_id: parseInt(admin_id, 10),
                }, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                const data = response.data.respons || [];
                dispatch(fetchCompaniesSuccess(data));
            }
            else {
                const data = [];
                dispatch(fetchCompaniesSuccess(data));
            }


        } catch (error) {
            dispatch(fetchCompaniesFailure(error.message));
        }
    };
}