
import React, { useState, useEffect ,useCallback} from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import './Facility.css';


const FacilityList = ({ facility = [], loading, error, fetchFacility,alldatanumber }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [facilityList, seFacilityList] = useState(['']);
    const itemsPerPage = 20;
    const [pageindex, setPageIdx] = useState(1)
    const [search, setSearch] = useState({ company_code: '', company_name: '', facility_id: '', facility_name: '' });

    useEffect(() => {
        fetchFacility(pageindex, itemsPerPage, search.company_code, search.company_name, search.facility_id, search.facility_name);
    }, [fetchFacility, location]);

    useEffect(() => {
        if (facility) {
            seFacilityList(facility);
        }
    }, [facility]);

    const handleSearchChange = (event) => {
        setSearch({ ...search, [event.target.name]: event.target.value });
    };
    
    const handleEditFacility = useCallback((facility_id) => {
        navigate(`/FacilityEdit?facility_id=${facility_id}`);
    }, []);

    const handleSearchSubmit = (event) => {
        if (event) event.preventDefault();

        fetchFacility(pageindex, itemsPerPage, search.company_code, search.company_name, search.facility_id, search.facility_name);
    };
    

    return (
        <div id='Main' >
            <div id='Contents'>
                <div id="ajax-view-disp">
                    <div className="display-main">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>施設一覧</span>
                                </div>

                                <form onSubmit={handleSearchSubmit} style={{ marginTop: '40px' }}>
                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_code" name="company_code" value={search.company_code} onChange={handleSearchChange}/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name" value={search.company_name} onChange={handleSearchChange}/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                           
                                        </ul>
                                    </div>

                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>施設ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="facility_id" name="facility_id" value={search.facility_id} onChange={handleSearchChange}/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>施設名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="facility_name" name="facility_name" value={search.facility_name} onChange={handleSearchChange}/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                            <button type="submit" className="btn_facilitylist"> 表示 </button>
                                            </li>
                                        </ul>
                                    </div>

                                </form>

                                <div className="lay-master-set__frame"style={{ marginTop: '40px' }}>
                                    <div className="lay-master__table">
                                        <button type="button" className='mod-btn__roundborderNew'onClick={() => navigate('/FacilityNew')}  > 新規作成 </button>
                                        <form id="FacilityList"style={{ marginTop: '40px' }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="col-login-id">組織名</th>
                                                        <th className="col-user-name">施設ID</th>
                                                        <th className="col-api-token">施設名</th>
                                                        <th className="col-api-token-date">作成日</th>
                                                        <th className="col-edit">編集</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {facilityList.map((val, index) => (
                                                        <tr key={index} className={val.is_delete === 1 ? 'disabled' : ''}>
                                                            <td className="col-login-id">{val.company_name}</td>
                                                            <td className="col-user-name">{val.facility_id}</td>
                                                            <td className="col-api-token">{val.facility_name}</td>
                                                            <td className="col-api-token-date">{val.create_date}</td>
                                                            <td className="col-companyedit">
                    
                                                                <button
                                                                    type="button"
                                                                    id={`facility_info_edit_${index}`}
                                                                    className="mod-btn__companylist" 
                                                                    onClick={() => handleEditFacility(val.facility_id)}
                                                                >  編集
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

FacilityList.propTypes = {
    facility: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    alldatanumber: PropTypes.number.isRequired,
    fetchFacility: PropTypes.func.isRequired
};

export default React.memo(FacilityList);