import ActionTypes from "../constants/ActionTypes";

const initialState = {
  loading: false,
  deletedLog: [],
  error: null
};
const deletedListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_DELETEDLOG_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_DELETEDLOG_SUCCESS:
      return { ...state, loading: false, deletedLog: action.payload };
    case ActionTypes.FETCH_DELETEDLOG_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default deletedListReducer;
