import ActionTypes from "../constants/ActionTypes";

const initialState = {
  loading: false,
  data: [],
  accessToken: null,
  error: null,
  companies: []
};
const userNewReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REGISTER_USER_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.REGISTER_USER_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case ActionTypes.REGISTER_USER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default userNewReducer;
