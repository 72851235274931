import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import './MaintenanceList.css';
import Modal from '../Modal/Modal';

//ユーザ権限


const MaintenanceList = ({ maintenance = [], loading, error, fetchMaintenance, alldatanumber }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [maintenanceList, setMaintenanceList] = useState(['']);
    const [search, setSearch] = useState({ company_code: '', company_name: '' });
    const itemsPerPage = 20;
    const [pageindex, setPageIdx] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [selectAll, setSelectAll] = useState(false);





    useEffect(() => {
        fetchMaintenance(pageindex, itemsPerPage, search.company_code, search.company_name);
    }, [fetchMaintenance, location]);

    useEffect(() => {
        if (maintenance) {
            setMaintenanceList(maintenance);
        }
    }, [maintenance]);

    const handleButtonClick = () => {
        navigate('/MaintenanceNew');
    };
    const handleClosePopup = () => {
        setShowPopup(false);
    };
    const handleOpenPopup = () => {
        setShowPopup(true);
    };
    const handleDeleteSelected = () => {
        const newList = maintenanceList.filter(item => !selectedItems.has(item.id));
        setMaintenanceList(newList);
        setSelectedItems(new Set());
        setSelectAll(false);
    };
    const handleCheckboxChange = (id) => {
        const newSelectedItems = new Set(selectedItems);
        if (selectedItems.has(id)) {
            newSelectedItems.delete(id);
        } else {
            newSelectedItems.add(id);
        }
        setSelectedItems(newSelectedItems);
        setSelectAll(newSelectedItems.size === maintenanceList.length);
    };
    const handleSelectAllToggle = () => {
        if (selectAll) {
            setSelectedItems(new Set());
        } else {
            const newSelectedItems = new Set();
            maintenanceList.forEach(item => newSelectedItems.add(item.id));
            setSelectedItems(newSelectedItems);
        }
        setSelectAll(!selectAll);
    };
    const handleSearchChange = (event) => {
        setSearch({ ...search, [event.target.name]: event.target.value });
    };

    const handleSearchSubmit = (event) => {
        if (event) event.preventDefault();
        
        fetchMaintenance(pageindex, itemsPerPage, search.company_code, search.company_name);
    };

    return (
        <div id='Main' >
            <div id='Contents'>
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage} />
                )}
                <div id="ajax-view-disp">
                    {/* 管理者一覧 */}
                    <div className="display-main" id="id_admin_list">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>メンテナンス設定一覧</span>
                                </div>
                                <form onSubmit={handleSearchSubmit} style={{ marginTop: '40px' }}>
                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_code" name="company_code" value={search.company_code} onChange={handleSearchChange}/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name" value={search.company_name} onChange={handleSearchChange}/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <button type="submit" className="btn_facilitylist"> 表示 </button>
                                            </li>

                                        </ul>
                                    </div>

                                </form>

                                <div className="lay-master-set__frame" style={{ marginTop: '40px' }}>
                                    <div className="lay-master__table">
                                        
                                    <ul style={{ display: 'flex', alignItems: 'center', gap: '40px', listStyle: 'none', padding: '0', margin: '0 0 20px 0' }}>
                                            <li>
                                                <button type="submit" className="btn_mailsearch" onClick={handleButtonClick} >追加 </button>
                                            
                                            </li>

                                            <li>
                                            {/* <button type="submit"  className="btn_mailsearchback" >削除</button> */}

                                                <button type="submit" disabled={!selectedItems.size} className="btn_mailsearchback" onClick={handleDeleteSelected}>削除</button>
                                            </li>
                                        </ul>

                                        <div className="mt20" style={{ marginBottom: '20px' }}>
                                            <div onClick={handleSelectAllToggle} style={{ cursor: 'pointer', color: 'blue' }}>
                                                全てのチェックを操作
                                            </div>   
                                        {/* <div  style={{ cursor: 'pointer', color: 'blue' }}>
                                            全てのチェックを操作
                                        </div>                                    */}
                                        </div>
      
                                        <form id="MaintenanceList" style={{ marginTop: '40px' }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="col-login-id">削除チェック</th>
                                                        <th className="col-user-name">組織ID</th>
                                                        <th className="col-api-token">組織名</th>
                                                        <th className="col-api-token-date">制御ユーザ権限</th>
                                                        <th className="col-companyedit">メッセージ</th>
                                                        <th className="col-companyedit">編集</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {maintenanceList.map((val, index) => (
                                                        <tr key={index} className="lay-tr">   
                                                        <td>
                                                            <input type="checkbox" checked={selectedItems.has(val.id)} onChange={() => handleCheckboxChange(val.id)} className="checkbox-input is_delete" style={{ WebkitAppearance: 'checkbox' }} />
                                                        </td>
                                                        <td className="col-login-id">hakaru_rental</td>
                                                        <td className="col-user-name">{val.company_name}</td>
                                                        <td className="col-api-token">一般</td>
                                                        <td className="col-api-token-date">テスト</td>
                                                        <td className="col-companyedit">
                                                            <button type="button" id={`messageEdit_btn${index}`} className="mod-btn__companylist" onClick={handleOpenPopup}>
                                                                編集
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

MaintenanceList.propTypes = {
    maintenance: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    alldatanumber: PropTypes.number.isRequired,
    fetchMaintenance: PropTypes.func.isRequired
};

export default React.memo(MaintenanceList);