import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DeletedLogList from "../components/DeletedLogList";
import { fetchDeletedLog } from "../actions/deletedloglist";

const mapStateToProps = (state) => {
  const { deletedLoglist } = state;
  console.log("deletedLoglist:", deletedLoglist);

  return {
    deletedLog: deletedLoglist.deletedLog,
    loading: deletedLoglist.loading,
    error: deletedLoglist.error
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDeletedLog
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DeletedLogList);