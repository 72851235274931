import ActionTypes from "../constants/ActionTypes";

const initialState = {
  loading: false,
  admins: [],
  error: null,
  all_data_count: 0
};
const adminListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ADMINS_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_ADMINS_SUCCESS:
      return { ...state, loading: false, admins: action.payload.data_list, alldatanumber: action.payload.all_data_count  };
    case ActionTypes.FETCH_ADMINS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    // case ActionTypes.REGISTER_ADMIN_REQUEST:
    //   return { ...state, loading: true };
    // case ActionTypes.REGISTER_ADMIN_SUCCESS:
    //   return { ...state, loading: false};
    // case ActionTypes.REGISTER_ADMIN_FAILURE:
    //   return { ...state, loading: false};
    default:
      return state;
  }
};

export default adminListReducer;
