import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import './UserList.css';
import Modal from '../Modal/Modal';

//ユーザ権限
const AUTHORITY_CLASS = {
    0: "一般",
    1: "管理者（一般）",
    9: "管理者（システム）",
};

const UserList = ({ users = [], loading, error, fetchUsers, alldatanumber }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [usersList, seUsersList] = useState(['']);
    const [search, setSearch] = useState({ company_code: '', company_name: '',  login_id: '', user_name: ''});
    const itemsPerPage = 20;
    const [pageindex, setPageIdx] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');


    // Fetch users list on component mount or when location changes
    useEffect(() => {
        fetchUsers(pageindex, itemsPerPage, search.company_code, search.company_name, search.login_id, search.user_name);
    }, [fetchUsers, location]);

    useEffect(() => {
        if (users) {
            seUsersList(users);
        }
    }, [users]);

    const handleButtonClick = () => {
        navigate('/UserNew');
    };

    const handleEditUser = useCallback((user_id) => {
        navigate(`/UserEdit?user_id=${user_id}`);
    }, []);

    const handleSearchChange = (event) => {
        setSearch({ ...search, [event.target.name]: event.target.value });
    };

    const handleSearchSubmit = (event) => {
        if (event) event.preventDefault();
        
        fetchUsers(pageindex, itemsPerPage, search.company_code, search.company_name, search.login_id, search.user_name);
    };

    return (
        <div id='Main' >
            <div id='Contents'>
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage} />
                )}
                <div id="ajax-view-disp">
                    {/* 管理者一覧 */}
                    <div className="display-main" id="id_admin_list">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>ユーザ一覧</span>
                                </div>
                                <form onSubmit={handleSearchSubmit} style={{ marginTop: '40px' }}>
                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_code" name="company_code" value={search.company_code} onChange={handleSearchChange}/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name" value={search.company_name} onChange={handleSearchChange}/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>

                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>ログインID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="login_id" name="login_id" value={search.login_id} onChange={handleSearchChange} />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>ユーザ名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="user_name" name="user_name" value={search.user_name} onChange={handleSearchChange}/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <button type="submit" className="btn_facilitylist"> 表示 </button>
                                            </li>
                                        </ul>
                                    </div>

                                </form>

                                <div className="lay-master-set__frame" style={{ marginTop: '40px' }}>
                                    <div className="lay-master__table">
                                        {/* <div className="get_api_management" style={{ float: 'left' }}> */}
                                        <button type="button" id="" className='mod-btn__roundborderNew' onClick={handleButtonClick}>
                                            新規作成
                                        </button>
                                        {/* </div> */}
                                        {/* テーブル */}
                                        <form id="UserList" style={{ marginTop: '40px' }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="col-login-id">組織名</th>
                                                        <th className="col-user-name">ログインID</th>
                                                        <th className="col-api-token">ユーザ名</th>
                                                        <th className="col-api-token-date">設定権限	</th>
                                                        <th className="col-companyedit">編集</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {usersList.map((user, index) => (
                                                        <tr key={index} className="lay-tr">
                                                            <td className="col-login-id">{user.company_name}</td>
                                                            <td className="col-user-name">{user.user_name}</td>
                                                            <td className="col-api-token">{user.login_id}</td>
                                                            <td className="col-api-token-date">{AUTHORITY_CLASS[user.authority_class] || ""}</td>
                                                            <td className="col-companyedit">
                                                                <button
                                                                    type="button"
                                                                    id={`admin_info_edit_${index}`}
                                                                    className="mod-btn__companylist"
                                                                    onClick={() => handleEditUser(user.user_id)}
                                                                >
                                                                    編集
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

UserList.propTypes = {
    users: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    alldatanumber: PropTypes.number.isRequired,
    fetchUsers: PropTypes.func.isRequired
};

export default React.memo(UserList);