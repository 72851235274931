import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../Modal/Modal';
import './MailEdit.css';
import { checkInput } from '../../helpers/validation';
import CompanySearch from '../CompanySearch/CompanySearch';
import { format } from 'date-fns';

const MailEdit = ({ fetchMailEdit, submitMailEdit, maildata = {}, loading, error }) => {
    const navigate = useNavigate();
    const [company_id, setCompany_id] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [abnormalAlertFlag, setAbnormalAlertFlag] = useState(false);
    const [batteryAlertFlag, setBatteryAlertFlag] = useState(false);
    const [batteryAlertAmount, setBatteryAlertAmount] = useState(''); 
    const [mail_to_list, setMailToList] = useState(['']);
    const [mail_cc_list, setMailCcList] = useState(['']);
    const [mail_bcc_list, setMailBccList] = useState(['']);
    const [errors, setErrors] = useState({});


    const handleAddMailTo = () => {
        setMailToList([...mail_to_list, '']); 
    };

    const handleAddMailCc = () => {
        setMailCcList([...mail_cc_list, '']);  
    };

    const handleAddMailBcc = () => {
        setMailBccList([...mail_bcc_list, '']);  
    };

    const handleChangeMailTo = (index, value) => {
        const newMailList = [...mail_to_list];
        newMailList[index] = value|| '';
        setMailToList(newMailList);
    };

    const handleChangeMailCc = (index, value) => {
        const newMailListCc = [...mail_cc_list];
        newMailListCc[index] = value|| '';
        setMailCcList(newMailListCc);
    };

    const handleChangeMailBcc = (index, value) => {
        const newMailListBcc = [...mail_bcc_list];
        newMailListBcc[index] = value|| '';
        setMailBccList(newMailListBcc);
    };

    useEffect(() => {
        fetchMailEdit();
    }, []);

    useEffect(() => {
        if (maildata) {
            setMailToList(maildata.mail_to_list || ['']);  
            setMailCcList(maildata.mail_cc_list || ['']);  
            setMailBccList(maildata.mail_bcc_list || ['']);  
            setAbnormalAlertFlag(maildata.abnormal_alert_flag === 1);
            setBatteryAlertFlag(maildata.battery_alert_flag === 1);
            setBatteryAlertAmount(maildata.battery_alert_amount || '');  
        }
    }, [maildata]);
    
    
    const handleBackClick = () => {
        navigate(-1);
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email); 
    };

    const validateAllEmails = () => {
        let isValid = true;
        const newErrors = {};

        mail_to_list.forEach((mail, index) => {
            if (mail && !validateEmail(mail)) {
                newErrors[`mail_to_${index}`] = "メールアドレスが正しくありません。";
                isValid = false;
            }
        });

        mail_cc_list.forEach((mail, index) => {
            if (mail && !validateEmail(mail)) {
                newErrors[`mail_cc_${index}`] = "メールアドレスが正しくありません。";
                isValid = false;
            }
        });

        mail_bcc_list.forEach((mail, index) => {
            if (mail && !validateEmail(mail)) {
                newErrors[`mail_Bcc_${index}`] = "メールアドレスが正しくありません。";
                isValid = false;
            }
        });

        setErrors(newErrors);
        return isValid;
    };

    const handleSaveFacility = async () => {
        const isValid = validateAllEmails();
        if (!isValid) {
            return; 
        }
        try {
            const payload = {
                mail_cc_list,
                mail_to_list,
                mail_bcc_list,
                abnormalAlertFlag,
                batteryAlertFlag,
                batteryAlertAmount,
                company_id,
            };
            const response = await submitMailEdit(payload);
            const message = response.data.respons?.msg || '登録が成功しました。';
            setModalMessage(message);
            setIsModalOpen(true); 
            setIsConfirmationModal(false); 
        } catch (error) {
            setModalMessage(error.message || '登録に失敗しました。');
            setIsModalOpen(true); 
            setIsConfirmationModal(false); 
        }
    };
    
    const handleConfirmAction = async () => {
        setIsModalOpen(false); 
        setIsFetching(true); 
        try {
            let response;
            const message = response?.data?.respons?.msg || '操作が成功しました。';
            setModalMessage(message);

            navigate(-1);
        } catch (error) {
            setModalMessage(error.message || 'エラーが発生しました。再試行してください。');
            setIsConfirmationModal(false);
            setIsModalOpen(true); 
        } finally {
            setIsFetching(false); 
        }
    };
    
    return (
        <div id='Main' >
            <div id='Contents'>
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => {
                            setIsModalOpen(false);
                            navigate(-1); 
                        }}                        
                        message={modalMessage} 
                        onConfirm={handleConfirmAction}
                        showCancelButton={isConfirmationModal}
                    />
                )}

                <div id="ajax-view-disp">
                    <div className="display-main">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>メール情報編集 </span>
                                </div>
                                <div className="lay-master-set__form">
                                    <form id="MailEdit">
                                        <div className="wrapper" style={{ textAlign: 'left' }}>
                                            <div className="mail-send__set">
                                                <div className="mail-input">
                                                    {/* ボタン */}
                                                    <div style={{ width: '100%', marginTop: '10px' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div className='add-btn'>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__roundborder add"
                                                                    onClick={handleSaveFacility}
                                                                >
                                                                    更新
                                                                </button>
                                                            </div>
                                                            
                                                            <div className='back-btn'>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__adminback back"
                                                                    onClick={handleBackClick}
                                                                >
                                                                    戻る
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mod-form__set">
                                                        <div className="checkbox-frame" style={{ marginBottom: '10px' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <div>
                                                                    <input type="checkbox" className="checkbox-input" 
                                                                        style={{ WebkitAppearance: 'checkbox', marginRight: '2px' }} 
                                                                        checked={abnormalAlertFlag}
                                                                        onChange={(e) => setAbnormalAlertFlag(e.target.checked)}
                                                                    />
                                                                </div>
                                                                
                                                                <div>
                                                                    <span style={{ fontSize: '18px' }}>
                                                                        データが異常値と判定された際にメールを送信する
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="checkbox-frame">
                                                            <div style={{ marginBottom: '30px', display: 'flex' }}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <div>
                                                                        <label>
                                                                            <input type="checkbox" className="checkbox-input" style={{ WebkitAppearance: 'checkbox', marginRight: '2px' }} 
                                                                                checked={batteryAlertFlag}
                                                                                onChange={(e) => setBatteryAlertFlag(e.target.checked)} 
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                    <div>
                                                                        <span style={{ fontSize: '18px' }}>
                                                                            電池残量が低下した場合にメールを送信する
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="battery-input">
                                                                    <input
                                                                        type="text"
                                                                        id="battery_alert_amount"
                                                                        name="battery_alert_amount"
                                                                        value={batteryAlertAmount}
                                                                        onChange={(e) => setBatteryAlertAmount(e.target.value)}
                                                                        pattern="\d*"
                                                                        maxLength="3"
                                                                        disabled={!batteryAlertFlag} 
                                                                        style={{background: batteryAlertFlag ? "#fff" : "#eee", pointerEvents: batteryAlertFlag ? 'auto' : 'none',}}
                                                                    />
                                                                    <span>%</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="mail-input">
                                                            <div style={{ width: '135%', marginTop: '10px' }}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <div>
                                                                        <span>TO</span>
                                                                    </div>
                                                          
                                                                    <div className="add-btn">
                                                                        <button
                                                                            type="button"
                                                                            className="mod-btn__mailedit add"
                                                                            onClick={handleAddMailTo}
                                                                            disabled={mail_to_list.length >= 10} 
                                                                            >追加
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {mail_to_list.map((mail, index) => (
                                                                <div key={index} className="mod-form__set">
                                                                    <dl style={{ marginTop: '10px' }}>
                                                                        <dt>{`宛先${index + 1}`}</dt>  
                                                                            <dd style={{ width: '65%' }}>
                                                                            <div className="mod-form__text">
                                                                                <input
                                                                                    type="text"
                                                                                    name={`mail_to_${index}`}
                                                                                    value={mail}
                                                                                    onChange={(e) => handleChangeMailTo(index, e.target.value)}
                                                                                />
                                                                                {errors[`mail_to_${index}`] && (
                                                                                    <div className="error_mail" style={{ color: 'red' }}>
                                                                                        {`宛先${index + 1}のメールアドレスが正しくありません。`}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            ))}
                                                        </div>

                                                        <div className="mail-input">
                                                            <div style={{ width: '135%', marginTop: '10px' }}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <div>
                                                                        <span>CC</span>
                                                                    </div>
                                                                    
                                                                    <div className="add-btn">
                                                                        <button
                                                                            type="button"
                                                                            className="mod-btn__mailedit add"
                                                                            onClick={handleAddMailCc}
                                                                            disabled={mail_cc_list.length >= 10}
                                                                            >追加
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {mail_cc_list.map((mail, index) => (
                                                                <div key={index} className="mod-form__set">
                                                                    <dl style={{ marginTop: '10px' }}>
                                                                        <dt>{`宛先${index + 1}`}</dt>  
                                                                            <dd style={{ width: '65%' }}>
                                                                            <div className="mod-form__text">
                                                                                <input
                                                                                    type="text"
                                                                                    name={`mail_cc_${index}`}
                                                                                    value={mail}
                                                                                    onChange={(e) => handleChangeMailCc(index, e.target.value)}
                                                                                />
                                                                                {errors[`mail_cc_${index}`] && (
                                                                                    <div className="error_mail" style={{ color: 'red' }}>
                                                                                        {`宛先${index + 1}のメールアドレスが正しくありません。`}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            ))}
                                                        </div>

                                                        <div className="mail-input">
                                                            <div style={{ width: '135%', marginTop: '10px' }}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <div>
                                                                        <span>BCC</span>
                                                                    </div>
                                                                    <div className="add-btn">
                                                                        <button
                                                                            type="button"
                                                                            className="mod-btn__mailedit add"
                                                                            onClick={handleAddMailBcc}
                                                                            disabled={mail_bcc_list.length >= 10}
                                                                            >追加
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {mail_bcc_list.map((mail, index) => (
                                                                <div key={index} className="mod-form__set">
                                                                    <dl style={{ marginTop: '10px' }}>
                                                                        <dt>{`宛先${index + 1}`}</dt>  
                                                                            <dd style={{ width: '65%' }}>
                                                                            <div className="mod-form__text">
                                                                                <input
                                                                                    type="text"
                                                                                    name={`mail_Bcc_${index}`}
                                                                                    value={mail}
                                                                                    onChange={(e) => handleChangeMailBcc(index, e.target.value)}
                                                                                />
                                                                                {errors[`mail_Bcc_${index}`] && (
                                                                                    <div className="error_mail" style={{ color: 'red' }}>
                                                                                        {`宛先${index + 1}のメールアドレスが正しくありません。`}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`overlay ${(loading || isFetching) ? 'active' : ''}`}>
                <div className={`spinner ${(loading || isFetching) ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

MailEdit.propTypes = {
    maildata: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchMailEdit: PropTypes.func.isRequired,
    submitMailEdit: PropTypes.func.isRequired
};

export default React.memo(MailEdit);