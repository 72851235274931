import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchMaintenanceRequest = () => ({
    type: ActionTypes.FETCH_MAINTENANCE_REQUEST,
});

export const fetchMaintenanceSuccess = (admins) => ({
    type: ActionTypes.FETCH_MAINTENANCE_SUCCESS,
    payload: admins
});

export const fetchMaintenanceFailure = (error) => ({
    type: ActionTypes.FETCH_MAINTENANCE_FAILURE,
    payload: error
});


export const fetchMaintenance = (pageindex, itemsPerPage, company_code, company_name) => {
    return async (dispatch) => {
        dispatch(fetchMaintenanceRequest());
        try {
            if (!window.debug) {
                let strFilter = "";
                if (company_code) {
                    strFilter = `&company_code=${company_code}`;
                }
                if (company_name) {
                    strFilter = `&company_name=${company_name}`;
                }
                
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.get(BASE_URL + `/path=maintenance&type=get-list-data&page=${pageindex}&number_per_page=${itemsPerPage}` + strFilter, 
                    {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                const data = response.data.respons || {"data_list": [], "all_data_count":0};
                dispatch(fetchMaintenanceSuccess(data));
            }
            else {
                const data = {"data_list": [{ "company_id": 1, "company_code": "hakaru", "company_name": "ハカルプラス"},
                { "company_id": 1, 'company_code': 'hakaru2', 'company_name': '東京都水道局'},
                { "company_id": 1, 'company_code': 'hakaru', 'company_name': '東京都水道局'},
                { "company_id": 1, 'company_code': 'hakaru', 'company_name': 'セイリツ工業株式会社'},
                {"company_id": 1, 'company_code': 'hakaru', 'company_name': '鴻池運輸株式会社'}
                ], "all_data_count": 5};
                dispatch(fetchMaintenanceSuccess(data));
            }


        } catch (error) {
            dispatch(fetchMaintenanceFailure(error.message));
        }
    };
};
