import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import './CompanyList.css';
import Modal from '../Modal/Modal';

const CompanyList = ({ fetchCompanies, fetchManagementUnitData, companies = [], loading, error, alldatanumber }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [search, setSearch] = useState({ company_code: '', company_name: '' });
    const [companiesList, setCompaniesList] = useState(companies);
    const itemsPerPage = 20;
    const [pageindex, setPageIdx] = useState(1);

    useEffect(() => {
        fetchCompanies(pageindex, itemsPerPage);
    }, [fetchCompanies, location]);

    useEffect(() => {
        if (companies.length > 0) {
            setCompaniesList(companies);
        }
    }, [companies]);

    const handleFetchManagementData = async () => {
        try {
            await fetchManagementUnitData();
            setModalMessage('取得しました。');
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error fetching management unit data:', error);
        }
    };

    const handleSearchChange = (event) => {
        setSearch({ ...search, [event.target.name]: event.target.value });
    };

    const handleSearchSubmit = (event) => {
        if (event) event.preventDefault();
        fetchCompanies(pageindex, itemsPerPage, search.company_code, search.company_name)
    };

    const handleCompanyEdit = useCallback((company_id) => {
        navigate(`/CompanyEdit?company_id=${company_id}`);
    }, []);

    return (
        <div id='Main' >
            <div id='Contents'>
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage}/>
                )}
                <div id="ajax-view-disp">
                    <div className="display-main" id="id_admin_list">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>組織一覧</span>
                                </div>

                                <form onSubmit={handleSearchSubmit} style={{ marginTop: '30px' }}>
                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_code" name="company_code" value={search.company_code} onChange={handleSearchChange} />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name" value={search.company_name} onChange={handleSearchChange} />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>


                                            <li>
                                                <button type="submit" className="btn_facilitylist"> 表示 </button>
                                            </li>
                                        </ul>
                                    </div>

                                </form>

                                <div className="lay-master-set__frame" style={{ marginTop: '40px' }}>
                                    <div className="lay-master__table">

                                        <button type="button" className='mod-btn__roundborderNew' onClick={handleFetchManagementData} > データ取得 </button>
                                        <form id="CompanyList" style={{ marginTop: '30px' }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="col-company-id">組織ID</th>
                                                        <th className="col-company-name">組織名</th>
                                                        <th className="col-company-unit">管理ユニットID</th>
                                                        <th className="col-company-unitname">管理ユニット名</th>
                                                        <th className="col-company-date">取得日</th>
                                                        <th className="col-company-edit">編集</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {companiesList.map((val, index) => (
                                                        <tr key={index} className={val.is_hidden === 1 ? 'disabled' : ''}>
                                                            <td className="col-login-id">{val.company_code}</td>
                                                            <td className="col-company-name">{val.company_name}</td>
                                                            <td className="col-company-unit">{val.manage_unit_id}</td>
                                                            <td className="col-company-unitname">{val.manage_unit_name}</td>
                                                            <td className="col-company-date">{val.create_date}</td>
                                                            <td className="col-company-edit">
                                                                <button
                                                                    type="button"
                                                                    id={`messageEdit_btn${index}`}
                                                                    className="mod-btn__companylist"
                                                                    onClick={() => handleCompanyEdit(val.company_id)}
                                                                >編集
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CompanyList.propTypes = {
    companies: PropTypes.array.isRequired,
    // managementUnitData: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    alldatanumber: PropTypes.number.isRequired,
    fetchCompanies: PropTypes.func.isRequired,
    fetchManagementUnitData: PropTypes.func.isRequired
};

export default React.memo(CompanyList);