
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './DeletedLogList.css';
import Modal from '../Modal/Modal';
import { useDispatch } from 'react-redux';


const DeletedLogList = ({ deletedLog = [], loading, error, fetchDeletedLog }) => {
    const navigate = useNavigate();
    const [deletedLogList, seDeletedLogList] = useState(['']);
    const [search, setSearch] = useState({ companyname: '', facilityname: '', facilityId: '', createDate: '' });


    useEffect(() => {
        fetchDeletedLog();
    }, [fetchDeletedLog, navigate]);

    useEffect(() => {
        if (deletedLog) {
            seDeletedLogList(deletedLog);
        }
    }, [deletedLog]);

    const handleSearchChange = (event) => {
        setSearch({ ...search, [event.target.name]: event.target.value });
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        const filteredList = deletedLog.filter((item) => {
            const searchcompanyname = search.companyname === '' || item.company_name.toLowerCase().includes(search.companyname.toLowerCase());
            const searchfacilityname = search.facilityname === '' || item.facility_id.toLowerCase().includes(search.facilityname.toLowerCase());
            const searchfacilityId = search.facilityId === '' || item.facility_name.toLowerCase().includes(search.facilityId.toLowerCase());
            const searchcreateDate = search.createDate === '' || item.create_date.includes(search.createDate);
            return searchcompanyname && searchfacilityname && searchfacilityId && searchcreateDate;
        });
        seDeletedLogList(filteredList);
    };
    

    return (
        <div id='Main' >
            <div id='Contents'>
                <div id="ajax-view-disp">
                    {/* 管理者一覧 */}
                    <div className="display-main" id="id_admin_list">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>削除組織ログ一覧</span>
                                </div>

                        
                                <form onSubmit={handleSearchSubmit}style={{ marginTop: '40px' }}>
                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>施設ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="facilityId" name="facilityId" value={search.facilityId} onChange={handleSearchChange}/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>施設名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="createDate" name="createDate" value={search.createDate} onChange={handleSearchChange}/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                            <button type="submit" className="btn_facilitylist"> 表示 </button>

                                            </li>
                                        </ul>
                                    </div>

                                </form>

                                <div className="lay-master-set__frame"style={{ marginTop: '40px' }}>
                                    <div className="lay-master__table">
                                        <form id="AdminList">
                                            <table>
                                                <thead>
                                                    <tr>
                                                    <th className="col-login-id">組織ID</th>
                                                        <th className="col-user-name">組織名</th>
                                                        <th className="col-user-name">管理ユニットID</th>
                                                        <th className="col-api-token">管理ユニット名</th>
                                                        <th className="col-api-token-date">削除日</th>
                                                        <th className="col-companyedit">詳細</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {deletedLogList.map((val, index) => (
                                                        <tr key={index} className="lay-tr">
                                                            <td className="col-login-id">{val.company_code}</td>
                                                            <td className="col-login-id">{val.company_name}</td>
                                                            <td className="col-user-name">{val.lilz_management_unit_id}</td>
                                                            <td className="col-api-token">{val.lilz_management_unit_name}</td>
                                                            <td className="col-api-token-date">{val.update_date}</td>
                                                            <td className="col-companyedit">
                                                                <button
                                                                    type="button"
                                                                    id={`admin_info_edit_${index}`}
                                                                    className="mod-btn__companylist"onClick={() => navigate('/DeletedLogEdit')} 
                                                                    >詳細
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

DeletedLogList.propTypes = {
    deletedLog: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchDeletedLog: PropTypes.func.isRequired
};

export default React.memo(DeletedLogList);