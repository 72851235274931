import ActionTypes from '../constants/ActionTypes';
import axiosInstance from '../helpers/refreshTokenMiddleware';
import BASE_URL from '../constants/Api';

export const updatePasswordRequest = () => ({
    type: ActionTypes.UPDATE_PASSWORD_REQUEST,
});

export const updatePasswordSuccess = (message) => ({
    type: ActionTypes.UPDATE_PASSWORD_SUCCESS,
    payload: message
});

export const updatePasswordFailure = (error) => ({
    type: ActionTypes.UPDATE_PASSWORD_FAILURE,
    payload: error
});

export const updatePassword = (currentPassword, newPassword, login_id, userType) => {
    return async (dispatch) => {
        dispatch(updatePasswordRequest());
        let path = "";
        let payload = {};
        if (userType == "admin") {
            path = 'path=admin&type=update-password';
            payload = {
                password_old: currentPassword,
                password_new: newPassword,
                admin_id: login_id
            }
        }
        else if (userType == "user"){
            path = 'path=user&type=update-password';
            payload = {
                password_old: currentPassword,
                password_new: newPassword,
                user_id: login_id
            }
        }

        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.post(BASE_URL + path, payload, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                if (response.data.status ==200) {
                    const message = "変更しました。";
                    dispatch(updatePasswordSuccess(message));
                    return message;
                }
            }
            else {
                const msg = "変更しました。";
                var response = {};
                response["data"] = { "respons": { "msg": msg } }
                dispatch(updatePasswordSuccess(msg));
                return msg;
            }
        } catch (error) {
            const errorMessage = error.response?.data?.respons?.msg || "Failed to update ID/PW.";
            dispatch(updatePasswordFailure(errorMessage));
            throw new Error(errorMessage);

        }
    };
};
