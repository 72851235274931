import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchCameraChangeRequest = () => ({
  type: ActionTypes.FETCH_CAMERA_CHANGE_DATA_REQUEST,
});

export const fetchCameraChangeSuccess = (data) => ({
  type: ActionTypes.FETCH_CAMERA_CHANGE_DATA_SUCCESS,
  payload: data,
});

export const fetchCameraChangeFailure = (error) => ({
  type: ActionTypes.FETCH_CAMERA_CHANGE_DATA_FAILURE,
  payload: error,
});

// Thunk to fetch data from API  
export const fetchCameraChange = (id) => {
  return async (dispatch) => {
    dispatch(fetchCameraChangeRequest());
    try {
      if (!window.debug) {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
          dispatch(fetchCameraChangeFailure('No access token found in localStorage'));
          return;
        }

        const response = await axiosInstance.get(BASE_URL + `path=camera&type=get-data&id=${id}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        const data = response.data.respons || {};
        dispatch(fetchCameraChangeSuccess(data));
      }
      else {
        const data = {
          "device_id": "24688000518003ee",
          "disused_flag": "1",
          "image_source": "% d",
          "api_facility_id": "9193807b-8dc5-4ff8-ac8f-124026307f5e",
          "camera_name": "24688000518003ee",
          "camera_display_name": "24688000518003ee_display",
          "facility_id": "1",
          "facility_name": "施設１",
          "company_id": "kamimaru",
          "company_code": "カミマル株式会社	",
          "company_name": "%s",
          "firmware_version": "0.0.e1.2.8",
          "battery_percent": "100",
          "create_date": "2024/11/19",
          "facility_list": [{ "facility_id": "1", "facility_name": "施設１", "facility_name_display": "施設１" }, { "facility_id": "2", "facility_name": "施設2", "facility_name_display": "施設２" }],
          "gaugeInfoList": [
            { "gauge_id": "1", "gauge_name": "% s", "gauge_display_name": "% s", "image_source": "% s" },
            { "gauge_id": "2", "gauge_name": "% s", "gauge_display_name": "% s", "image_source": "% s" }]
        };

        dispatch(fetchCameraChangeSuccess(data));
      }
    } catch (error) {
      dispatch(fetchCameraChangeFailure(error.message));
    }
  };
};


export const fetchGetGaugeDataSuccess = (dataCount) => ({
  type: ActionTypes.FETCH_GET_GAUGE_DATA_SUCCESS,
  payload: dataCount,
});

export const fetchGetGaugeDataFailure = (error) => ({
  type: ActionTypes.FETCH_GET_GAUGE_DATA_FAILURE,
  payload: error,
});

// Thunk action to submit camera edit form data
export const fetchGetGaugeData = (camera_id) => {
  return async (dispatch) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const body = {
        camera_id: camera_id,
      };
      const response = await axiosInstance.post(BASE_URL + '/setting/get-gauge-data', body, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      dispatch(fetchGetGaugeDataSuccess(response.data.respons.dataCount));
      return response;
    } catch (error) {
      // Dispatch failure action if the API call fails
      dispatch(fetchGetGaugeDataFailure(error.message));
    }
  };
};

export const sendCameraChangeRequest = () => ({
  type: ActionTypes.CAMERA_CHANGE_MAIL_SEND_REQUEST,
});

export const sendCameraChangeSuccess = (message) => ({
  type: ActionTypes.CAMERA_CHANGE_MAIL_SEND_SUCCESS,
  payload: message,
});

export const sendCameraChangeFailure = (error) => ({
  type: ActionTypes.CAMERA_CHANGE_MAIL_SEND_FAILURE,
  payload: error,
});

export const sendCameraChange = (payload) => {
  return async (dispatch) => {
    dispatch(sendCameraChangeRequest());
    try {
      if (!window.debug) {

        const accessToken = localStorage.getItem('accessToken');
        const response = await axiosInstance.post(BASE_URL + 'path=camera&type=update-info-data', payload, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        });

        if (response.data.status == 200) {
          const message = "変更しました。";
          dispatch(sendCameraChangeSuccess(message));
          return message;
        }
      }
      else {
        const msg = "変更しました。";
        var response = {};
        response["data"] = { "respons": { "msg": msg } }
        dispatch(sendCameraChangeSuccess(msg));
        return response;
      }
    } catch (error) {
      // Dispatch failure action if the API call fails
      dispatch(sendCameraChangeFailure(error.message));
    }
  };
};

export const fetchCameraDeleteRequest = () => ({
  type: ActionTypes.CAMERA_DELETE_REQUEST
});

export const fetchCameraDeleteSuccess = (camera) => ({
  type: ActionTypes.CAMERA_DELETE_SUCCESS,
  payload: camera
});

export const fetchCameraDeleteFailure = (error) => ({
  type: ActionTypes.CAMERA_DELETE_FAILURE,
  payload: error
});
export const deleteCamera = (device_id) => {
  return async (dispatch) => {
    dispatch(fetchCameraDeleteRequest());
    try {
      if (!window.debug) {
        const accessToken = localStorage.getItem('accessToken');
        const response = await axiosInstance.post(BASE_URL + 'path=camera&type=delete-data', { 'device_id': device_id }, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        });

        // Check response here
        if (response.data.status == 200) {
          const message = "完全に削除しました。";
          dispatch(fetchCameraDeleteSuccess(message));
          return message;
        }
      }
      else {
        const msg = "完全に削除しました。";
        var response = {};
        response["data"] = { "respons": { "msg": msg } }
        dispatch(fetchCameraDeleteSuccess(msg));
        return response;
      }

    } catch (error) {
      dispatch(fetchCameraDeleteFailure(error.message));
    }
  };
};

