import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import './MaintenanceNew.css';
import { useDispatch } from 'react-redux';
// import MailSearch from './MailSearch';
// import CompanySearch from '../CompanySearch/CompanySearch';



const MaintenanceNew = ({ maintenance = [], loading, error, fetchMaintenance,companies }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [maintenanceList, setMaintenanceList] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState({});
    const [search, setSearch] = useState({ company_code: '', company_name: ''});


    useEffect(() => {
        fetchMaintenance();  
    }, [fetchMaintenance, location]);
    
    const handleBackClick = () => {
        if (window.history.length > 2) {
            navigate(-1);
        }
    };
    useEffect(() => {
        if (maintenance) {
            setMaintenanceList(maintenance);
        }
    }, [maintenance]);

    const handleEditClick = (admin) => {
        setSelectedAdmin(admin);
        setShowPopup(true);
    };
    

    const handleOpenPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };
    const handleSearchChange = (event) => {
        setSearch({ ...search, [event.target.name]: event.target.value });
    };


    const handleSearchSubmit = (event) => {
        event.preventDefault();
        const filteredList = maintenance.filter((item) => {
            const searchcompany_code = search.company_code === '' || item.company_code.toLowerCase().includes(search.company_code.toLowerCase());
            const searchcompany_name = search.company_name === '' || item.company_name.toLowerCase().includes(search.company_name.toLowerCase());
            return searchcompany_code && searchcompany_name ;
        });
        setMaintenanceList(filteredList);
    };

    return (
        <div id='Main' >
            <div id='Contents'>

                {/* {showPopup && (
                    <MailSearch onClose={handleClosePopup} />
                )} */}
                {/* {showPopup && (<MailSearch onClose={handleClosePopup}/>)} */}
                <div id="ajax-view-disp">
                    <div className="display-main" id="id_admin_list">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>メンテナンス新規登録
                                    </span>
                                </div>
                        
                                <form onSubmit={handleSearchSubmit}style={{ marginTop: '40px' }}>
                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_code" name="company_code" value={search.company_code} onChange={handleSearchChange}/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name" value={search.company_name} onChange={handleSearchChange}/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>


                                            <li>
                                            <button type="submit" className="btn_facilitylist"> 表示 </button>
                                            </li>
                                        </ul>
                                    </div>

                                </form>

                                <div className="lay-master-set__frame"style={{ marginTop: '40px' }}>

                                    <div className="lay-master__table">
                                   
                                        <div  style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className="mod-form-graph__set">
                                                <dl>
                                                    <dt>メッセージ</dt>
                                                    <dd>
                                                        <div className='mod-form-maintenance__text'>
                                                            <input type="text" id="company_code" name="company_code" value={search.company_code} onChange={handleSearchChange}/>
                                                        </div>
                                                    </dd>
                                                </dl>
                                            </div>

                                            <ul style={{ display: 'flex',  gap: '40px', listStyle: 'none', marginLeft: '23%'  }}>
                                                <li>
                                                    <button type="submit" className="btn_mailsearch">登録</button>
                                                </li>
            
                                                <li>
                                                    <button type="submit" className="btn_mailsearchback" onClick={handleBackClick}>戻る
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="mt20" style={{ marginTop: '30px', display: 'flex', alignItems: 'center' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <label style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', marginRight: '10px' }}> 
                                                    <input type="checkbox" className="checkbox-input" style={{ WebkitAppearance: 'checkbox', marginRight: '2px' }} />
                                                        一般
                                                </label>
    
                                                <label style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                                    <input type="checkbox" className="checkbox-input" style={{ WebkitAppearance: 'checkbox', marginRight: '2px' }} />
                                                        管理者（一般）
                                                </label>
                                            </div>
                                                <label id="check_all" style={{ color: 'blue', marginLeft: 'auto'}}>全てのチェックを操作</label>
                                        </div>

                                        <form id="AdminList"style={{ marginTop: '40px' }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        
                                                        <th className="cot1">制御ユーザ権限</th>
                                                        <th className="cot2">メッセージ</th>
                                                        <th className="cot3">編集</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {maintenanceList.map((val, index) => (
                                                        <tr key={index} className="lay-tr">
                                                        
                                                        <td className="cot1">kamimaru</td>
                                                        <td className="cot2">{val.company_name}</td>
                                                        <td>
                                                            <input type="checkbox" className="cot3" style={{ WebkitAppearance: 'checkbox' }} />
                                                        </td>
                                                    </tr>
                                                    
                                                    ))}
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

MaintenanceNew.propTypes = {
    maintenance: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchMaintenance: PropTypes.func.isRequired,

};

export default React.memo(MaintenanceNew);
