import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MaintenanceNew from "../components/MaintenanceNew";
import { fetchMaintenance } from "../actions/maintenancenew";

const mapStateToProps = (state) => {
  const { maintenancenew } = state;
  console.log("maintenancenew:", maintenancenew);

  return {
    maintenance: maintenancenew.maintenance,
    loading: maintenancenew.loading,
    error: maintenancenew.error
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMaintenance
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceNew);