import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import './AdminList.css';


const AdminList = ({ admins = [], loading, error, fetchAdmins, alldatanumber }) => {
    const itemsPerPage = 20;
    const location = useLocation();
    const navigate = useNavigate();
    const [adminsList, setAdminsList] = useState([{}]);
    const [pageindex, setPageIdx] = useState(1);

    useEffect(() => {
        fetchAdmins(pageindex, itemsPerPage);
    }, [fetchAdmins, location]);

    useEffect(() => {
        if (admins) {
            setAdminsList(admins);
        }
    }, [admins]);

    const handleButtonClick = () => {
        navigate('/AdminNew');
    };
    

    const handleEditAdmin = useCallback((admin_id) => {
        navigate(`/AdminEdit?admin_id=${admin_id}`);
    }, []);

    return (
        <div id='Main' >
            <div id='Contents'>
                <div id="ajax-view-disp">
                    {/*管理者一覧 */}
                    <div className="display-main" id="id_admin_list">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>管理者一覧</span>
                                </div>
                                <div className="lay-master-set__frame" >
                                    <div className="lay-master__table">
                                        {/* <div className="get_api_management" style={{ float: 'left' }}> */}
                                        <button type="button" id="" className='mod-btn__roundborderNew' onClick={handleButtonClick}>
                                            新規作成
                                        </button>
                                        {/* </div> */}
                                        {/* テーブル */}
                                        <form id="AdminList"style={{ marginTop: '40px' }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="col-login-id">ログインID</th>
                                                        <th className="col-user-name">ユーザ名</th>
                                                        <th className="col-api-token">LilzGauge APIトークン登録</th>
                                                        <th className="col-api-token-date">LilzGauge APIトークン登録日</th>
                                                        <th className="col-companyedit">編集</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {adminsList.map((admin, index) => (
                                                        <tr key={index} className="lay-tr">
                                                            <td className="col-login-id">{admin.login_id}</td>
                                                            <td className="col-user-name">{admin.admin_name}</td>
                                                            <td className="col-api-token">{admin.lilz_gauge_token ? '有' : '無'}</td>
                                                            <td className="col-api-token-date">{admin.lilz_gauge_api_token_date}</td>
                                                            <td className="col-companyedit">
                                                                <button
                                                                    type="button"
                                                                    id={`admin_info_edit_${index}`}
                                                                    className="mod-btn__companylist"
                                                                    onClick = {() => handleEditAdmin(admin.admin_id)}
                                                                >
                                                                    編集
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

AdminList.propTypes = {
    admins: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    alldatanumber: PropTypes.number.isRequired,
    fetchAdmins: PropTypes.func.isRequired
};

export default React.memo(AdminList);